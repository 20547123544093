import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import EmployerMainOne from "./EmployerMainOne";
import NominationEmployer from "./Nomination";
import AboutEmployer from "./AboutEmployer";
import Privacy from "../final Block/Gdpr";
import ContactDetailsEmployer from "./ContactDetailsEmployer";
import AboutEmployerTwo from "./AboutEmployerTwo";
import StudentBlock from "../final Block/StudentBlock";
import InitiativeEmployer from "./Intiative";
import EmployerOrganisation from "./ContactDetailsOrganisationEmployer";
import AboutInitiative from "./AboutIntiative";
import NominationOrganisation from "./NominationOrganisation";
import AboutOrganisation from "./aboutOrganisation";
import ContactDetailsAboutOrganisation from "./ContactDetailsAboutOrganisation";
import AboutOrganisationDetails from "./AboutOrganisationDetails";
import NominationIndividual from "./NominationIndividual";
import FailIndividual from "../Fail Block/FailIndividual";
import ContactDetailsIndividualOne from "./ContactDetailsIndividualOne";
import AboutPerson from "./AboutPerson";
import ContactDetailsIndividualTwo from "./ContactDetailsIndividualTwo";
import AboutPersonOne from "./AboutPersonOne";
import FailBlock from "../Fail Block/Fail";
const StyledWrapper = styled.div`
  .submit-form {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .back-btn {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .form__label {
    font-family: Nunito Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    display: block;
    letter-spacing: 0px;
    text-align: left;
  }

  .astrix-color {
    color: #e43d5a;
  }
`;

const EmployerMain = ({
  step,
  setStep,
  commonLayoutData,
  setCommonLayoutData,
  submitForm,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    submitForm({ ...data });
  };
  const [internalStep, setInternalStep] = useState(1);
  return (
    <StyledWrapper>
      {internalStep == 1 && (
        <EmployerMainOne
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          internalStep={internalStep}
          setInternalStep={setInternalStep}
        />
      )}
      {internalStep == 2 &&
        formData.Employer_award === "Employer of the Year" && (
          <NominationEmployer
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.Employer_award === "Employer of the Year" && (
          <AboutEmployer
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.Employer_award === "Employer of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Employer of the Year" && (
          <ContactDetailsEmployer
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Employer of the Year" && (
          <AboutEmployerTwo
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Employer of the Year" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Employer of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 2 &&
        formData.Employer_award ===
          "Highest Impact New Initiative of the Year" && (
          <NominationEmployer
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.Employer_award ===
          "Highest Impact New Initiative of the Year" && (
          <InitiativeEmployer
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.Employer_award ===
          "Highest Impact New Initiative of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award ===
          "Highest Impact New Initiative of the Year" && (
          <EmployerOrganisation
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award ===
          "Highest Impact New Initiative of the Year" && (
          <AboutInitiative
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award ===
          "Highest Impact New Initiative of the Year" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}{" "}
      {internalStep == 6 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award ===
          "Highest Impact New Initiative of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 2 && formData.Employer_award === "Local Champion" && (
        <NominationOrganisation
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          internalStep={internalStep}
          setInternalStep={setInternalStep}
        />
      )}
      {internalStep == 3 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an organisation" &&
        formData.Employer_award === "Local Champion" && (
          <NominationEmployer
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an organisation" &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.Employer_award === "Local Champion" && (
          <AboutOrganisation
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an organisation" &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.Employer_award === "Local Champion" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an organisation" &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <ContactDetailsAboutOrganisation
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an organisation" &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <AboutOrganisationDetails
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an organisation" &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 7 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an organisation" &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an individual" &&
        formData.Employer_award === "Local Champion" && (
          <NominationIndividual
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an individual" &&
        formData.Are_you_nominating_yourself_for_this_award === "yes" &&
        formData.Employer_award === "Local Champion" && (
          <FailBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an individual" &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <ContactDetailsIndividualOne
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an individual" &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <AboutPerson
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an individual" &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 7 &&
        formData.nominating_an_individual_or_an_organisation_for_this_award ===
          "I'm nominating an individual" &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Local Champion" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 2 &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <NominationIndividual
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.Are_you_nominating_yourself_for_this_award === "yes" &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <FailIndividual
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 4 &&
        formData.Are_you_nominating_yourself_for_this_award === "yes" &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.Are_you_nominating_yourself_for_this_award === "yes" &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <ContactDetailsIndividualTwo
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <AboutPersonOne
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.Are_you_nominating_yourself_for_this_award === "no" &&
        formData.Employer_award === "Champion of Social Mobility" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
    </StyledWrapper>
  );
};

export default EmployerMain;
