import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const SectorTwo = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [wordLength, setWordLength] = useState(null);
  const [wordLengthTwo, setWordLengthTwo] = useState(null);
  const [wordLengthThree, setWordLengthThree] = useState(null);
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  const handelLength = val => {
    if (val.split(" ").length >= 200) {
      setWordLength(val.length);
      return;
    } else if (val.split(" ").length < 200) {
      setWordLength(null);
    }
  };
  const handelLengthOne = val => {
    if (val.split(" ").length >= 150) {
      setWordLengthTwo(val.length);
      return;
    } else if (val.split(" ").length < 150) {
      setWordLengthTwo(null);
    }
  };
  const handelLengthThree = val => {
    if (val.split(" ").length >= 75) {
      setWordLengthThree(val.length);
      return;
    } else if (val.split(" ").length < 75) {
      setWordLengthThree(null);
    }
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Their achievements and background</h1>
        <Form.Label className="form__label my-3">
          Describe the sector-specific achievements of the Undergraduate you are
          nominating for this Award. While it may sometimes be appropriate to
          recognise their journey to and at university, we recommend focusing on
          their achievements in the 2021-22 academic year. (200 words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          maxLength={wordLength}
          onChange={e => handelLength(e.target.value)}
          as="textarea"
          placeholder=""
          name="Describe the sector-specific achievements of the Undergraduate you are nominating for this Award While it may sometimes be appropriate to recognise their journey to and at university, we recommend focusing on their achievements in the 2021-22 academic year."
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Tell us more about their background to give context to the
          achievements you've described above. (150 words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          maxLength={wordLengthTwo}
          onChange={e => handelLengthOne(e.target.value)}
          as="textarea"
          placeholder=""
          name="Tell us more about their background to give context to the achievements you've described above."
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Is there anything else relevant you would like to add to help the
          judges make their decision? (75 words max)
        </Form.Label>
        <Form.Control
          maxLength={wordLengthThree}
          onChange={e => handelLengthThree(e.target.value)}
          as="textarea"
          placeholder=""
          name="Is there anything else relevant you would like to add to help the judges make their decision?"
          ref={register({ required: false })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default SectorTwo;
