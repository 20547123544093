import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const Referee = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Details of referee</h2>
        <p className="text-muted">
          Please provide details of a student referee, who will be required to
          submit a short personal statement in support of your nomination.
          Typically this might be someone who has benefitted from this work.
        </p>
        <Form.Label className="form__label my-3">
          First Name<span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="First Name"
          name="Referee first Name"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Last name<span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Last name"
          name="Referee Last Name"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Email address<span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Email address"
          name="Referee Email address"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Course studied<span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Course studied"
          name="Referee Course studied"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Graduation year<span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Graduation year"
          name="Referee Graduation year"
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default Referee;
