import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";

const StyledWrapper = styled.div``;

const UndergraduateMainOne = ({
  setStep,
  setInternalStep,
  internalStep,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState("");
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2>Focus area for your nomination</h2>
          <Form.Label className="form__label my-3">
            What is your primary reason for nominating someone for an
            undergraduate award? The area in which the undergraduate I am
            nominating has achieved most is...
            <span className="astrix-color">*</span>
          </Form.Label>
          <select
            name="reason_for_undergraduate_award"
            className="select-form p-2"
            ref={register({ required: true })}
          >
            <option value="">
              Select your primary reason for nominating someone for an
              undergraduate award
            </option>
            <option value="Academic achievement">Academic achievement</option>
            <option value="Specific career sector achievement">
              Specific career sector achievement
            </option>
            <option value="Improving social mobility on a national or local level">
              Improving social mobility on a national or local level
            </option>
          </select>
          <div className="row">
            <div className="col-6">
              <Button className="back-btn mt-3 p-2" onClick={() => setStep(1)}>
                Back
              </Button>
            </div>
            <div className="col-6">
              <Button type="submit" className="submit-form mt-3 p-2">
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </StyledWrapper>
  );
};

export default UndergraduateMainOne;
