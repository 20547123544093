import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";

const StyledWrapper = styled.div``;

const FailTeachers = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
  submit,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    submit(formData);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <p>
          <strong>
            We're sorry. Only teachers who have taught students aged 16 and over
            in the 2021-22 academic year are eligible for this Award.
          </strong>
        </p>

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default FailTeachers;
