import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, Button, Dropdown } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
  .searchBox {
    width: 100%;
  }
  .chip {
    background: rgb(242, 184, 66);
    color: black;
  }
  .err-msg {
    padding-top: 1rem;
    font-size: 0.9rem;
    color: red;
  }
`;

const SectorOne = ({
  setStep,
  setInternalStep,
  internalStep,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [sectors, setSectors] = useState([]);
  const [errMsg, setErrMsg] = useState(null);
  const onSubmit = data => {
    const sector = [];
    sectors.map(item => {
      sector.push({ name: item.name });
    });
    if (sector.length == 0) {
      setErrMsg(
        "Please select the career sector of the undergraduate you are nominating."
      );
      return;
    } else {
      setErrMsg("");
    }
    setFormData({ ...formData, ...data, sector });
    setInternalStep(internalStep + 1);
  };
  const handleSelect = e => {
    const arr = [];
    setSectors(e);
  };
  const options1 = [
    "Banking, Finance & Insurance",
    "Creative Industries & FMCG",
    "Government, Politics & The Public Sector",
    "Law",
    "Professional Services & Consulting",
    "Property",
    "Science & Engineering",
    "Tech",
  ];
  const options = [];
  options1.map((item, index) => {
    options.push({ name: item, id: index });
  });
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Which sector(s)?</h1>
        <Form.Label className="form__label my-3">
          In which career sector(s) has the Undergraduate you are nominating
          achieved outstanding success?{" "}
          <strong>
            Please note that you can select a maximum of two sectors.
          </strong>
          <span className="astrix-color">*</span>
        </Form.Label>
        <Multiselect
          options={options} // Options to display in the dropdown
          displayValue="name"
          selectionLimit={2}
          onSelect={e => handleSelect(e)}
          placeholder={
            "Select the career sector of the undergraduate you are nominating."
          } // Property name to display in the dropdown options
        />
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
        <p className="err-msg">{errMsg}</p>
      </Form>
    </StyledWrapper>
  );
};

export default SectorOne;
