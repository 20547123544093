import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const AboutMentor = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [wordLength, setWordLength] = useState(null);
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  const handelLength = val => {
    if (val.split(" ").length >= 200) {
      setWordLength(val.length);
      return;
    } else if (val.split(" ").length < 200) {
      setWordLength(null);
    }
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Tell us about your mentor</h1>
        <Form.Label className="form__label my-3">
          Why are you nominating this mentor for this award? How have they made
          a significant contribution to improving the employability of students
          from less-advantaged backgrounds? Please focus on activities in the
          2021-22 academic year. (200 words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          onChange={e => handelLength(e.target.value)}
          maxLength={wordLength}
          as="textarea"
          placeholder=""
          name="Why are you nominating this mentor for this award? How have they made a significant contribution to improving the employability of students from less-advantaged backgrounds? Please focus on activities in the 2021-22 academic year."
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default AboutMentor;
