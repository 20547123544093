import React, { useRef, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SummaryBlock from "../components/Reusables/SummaryBlock";
import summaryimage1 from "../images/pageImages/Nomination/summary1.png";
import Accordion from "../components/About/Accordion";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import FormModal from "../components/Nomination/FormModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NominationFormModal from "../components/Nomination/NominationFormModal";
import ReactGA from "react-ga";
import { useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";

const StyledWrapper = styled.div`
  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-size: 32px;
  }

  .bottom-div {
    background: black;
    color: ${props => props.theme["borderBottomColor"]};
  }

  .end-div {
    font-size: 48px;
    color: ${props => props.theme["borderBottomColor"]};
    text-decoration: underline;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }

  .toast-color {
    background: #f2b842;
  }

  .progressClass {
    background: white;
  }

  .toastBodyClass {
    color: black;
  }
`;

const body = (
  <div>
    <p>
      The nomination period for the Student Social Mobility Awards will begin in
      the second week of April.
    </p>
    <p>
      We will be posting more information about the nomination process in due
      course.
    </p>
  </div>
);

const body2 = (
  <div>
    <p>
      A Student Vote will help to determine the winners of four of our Awards.
      The four categories in question are the Outstanding Achievement Award,
      Biggest Champion of Social Mobility, Best Employer Initiative and Best
      University Initiative.
    </p>
    <p>
      The independent Judging Panel will select the winners of the other Awards,
      as well as the shortlists for the Outstanding Achievement Award and
      Biggest Champion of Social Mobility.
    </p>
    <p>All UK students will be eligible to vote, and can vote only once.</p>
  </div>
);

const summary = {
  title: "Our nomination process",
  description: body,
};

const summary2 = {
  title: "the student vote",
  description: body2,
  image: `${summaryimage1}`,
};
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Nomination />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/nominationpage`);
};
function Nomination() {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("Nomination", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  const handleClick = () => {
    ReactGA.event({
      category: "Nominations Page",
      action: "Start Nomination Button Click",
    });
  };
  const inputRef3 = useRef(null);
  useEffect(() => {
    if (newData && newData.showSummaryBlock2 === true) {
      inputRef3.current.children[0].children[0].children[0].children[0].children[0].children[5].onclick = function (
        event
      ) {
        ReactGA.event({
          category: "Nominations Page",
          action: "Student Vote Sign-up Button Click",
        });
      };
    }
  }, []);
  const [modalOpen, setModalOpen] = React.useState();
  const [nominationModalOpen, setNominationModalOpen] = React.useState(false);

  const notifyToast = () =>
    toast("Your details have been submitted", {
      className: "toast-color",
      progressClassName: "progressClass",
      bodyClassName: "toastBodyClass",
    });

  React.useEffect(() => {
    if (window.location.href.includes("isStudentVol=true")) {
      setModalOpen(true);
    }
    if (modalOpen == false) {
      window.history.replaceState({}, document.title, "/nominations");
      setModalOpen(false);
    }
  }, [modalOpen]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} />}

        {newData && newData.showSummaryBlock1 && (
          <div className="py-3">
            {newData.SummaryBlock1 && (
              <SummaryBlock summary={newData.SummaryBlock1} />
            )}
          </div>
        )}
        {newData && newData.ShowNominationForm && (
          <div className="container-fluid bottom-div mt-0">
            <div
              className="col-12 py-4 d-flex justify-content-center"
              style={{ cursor: "pointer" }}
              onClick={() => setNominationModalOpen(true)}
            >
              <AnchorLink className="end-div">
                <span onClick={handleClick}>MAKE A NOMINATION</span>
              </AnchorLink>
            </div>
          </div>
        )}
        {newData && newData.showAccordion && (
          <div className="container-fluid white-background py-5">
            <div className="pt-5">
              {newData.Accordion && <Accordion data={newData.Accordion} />}
            </div>
          </div>
        )}
        {/* <div className="white-background"><SummaryBlock summary={pageData.SummaryBlock2} /></div> */}

        {modalOpen && (
          <FormModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            notify={notifyToast}
          />
        )}
        {nominationModalOpen && (
          <NominationFormModal
            setModalOpen={setNominationModalOpen}
            modalOpen={nominationModalOpen}
          />
        )}
        <ToastContainer />
      </StyledWrapper>
    </Layout>
  );
}
