import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const AboutYourself = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [wordLength, setWordLength] = useState(null);
  const [wordLengthThree, setWordLengthThree] = useState(null);
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  const handelLength = val => {
    if (val.split(" ").length >= 200) {
      setWordLength(val.length);
      return;
    } else if (val.split(" ").length < 200) {
      setWordLength(null);
    }
  };
  const handelLengthThree = val => {
    if (val.split(" ").length >= 75) {
      setWordLengthThree(val.length);
      return;
    } else if (val.split(" ").length < 75) {
      setWordLengthThree(null);
    }
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Tell us about yourself</h1>
        <Form.Label className="form__label my-3">
          What is the name of your school?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the name of your school"
          name="What is the name of your school?"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is your school's postcode?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your school's postal code"
          name="school_postcode"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What activities have you undertaken to broaden career horizons, boost
          aspirations or facilitate the future success of students from less
          advantaged backgrounds? Please give particular focus on activities
          that have taken place in the 2021-22 academic year. (200 words max){" "}
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          onChange={e => handelLength(e.target.value)}
          maxLength={wordLength}
          as="textarea"
          placeholder=""
          name="What activities have you undertaken to broaden career horizons, boost aspirations or facilitate the future success of students from less advantaged backgrounds? Please give particular focus on activities that have taken place in the 2021-22 academic year."
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Describe the impact of these activities, with a focus on outcomes
          achieved and numbers impacted . (200 words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          onChange={e => handelLength(e.target.value)}
          maxLength={wordLength}
          as="textarea"
          placeholder=""
          name="Describe the impact of these activities, with a focus on outcomes achieved and numbers impacted."
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Is there anything else relevant you would like to add to help the
          judges make their decision? (75 words max)
        </Form.Label>
        <Form.Control
          maxLength={wordLengthThree}
          onChange={e => handelLengthThree(e.target.value)}
          as="textarea"
          placeholder=""
          name="Is there anything else relevant you would like to add to help the judges make their decision?"
          ref={register({ required: false })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default AboutYourself;
