import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const AspireMainOne = ({
  setStep,
  setInternalStep,
  internalStep,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Which award?</h2>
        <Form.Label className="form__label my-3">
          Which Aspire Award are you making a nomination for?
          <span className="astrix-color">*</span>
        </Form.Label>
        <select
          name="aspire_award"
          className="select-form p-2"
          ref={register({ required: true })}
        >
          <option value="">Select your Aspire Award</option>
          <option value="Aspire Rising Star">Aspire Rising Star</option>
          <option value="Aspire Community Champion">
            Aspire Community Champion
          </option>
          <option value="Aspire Teacher of the Year">
            Aspire Teacher of the Year
          </option>
          <option value="Aspire School of the Year">
            Aspire School of the Year
          </option>
        </select>
        <div className="row">
          <div className="col-6">
            <Button className="back-btn mt-3 p-2" onClick={() => setStep(1)}>
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default AspireMainOne;
