import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .form__label {
    font-family: Nunito Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }

  .astrix-color {
    color: #e43d5a;
  }

  .form__label {
    display: block;
  }

  .select-form {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25em;
    outline: none;
    width: 100%;
    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }

  .submit-form {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 30%;
    border: 0px;
  }
`;

export default function NominationFormCommonLayout({
  setCommonLayoutData,
  setStep,
  commonLayoutData,
  setFormData,
  formData,
}) {
  const { register, handleSubmit, errors, watch } = useForm();

  const [nominate, setNominate] = useState("");
  const onSubmit = data => {
    setFormData(data);
    setCommonLayoutData(data);
    setStep(2);
  };

  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Your details</h2>
        <Form.Label className="form__label my-3">
          Your First Name. <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          defaultValue={
            commonLayoutData !== null ? commonLayoutData.firstName : ""
          }
          placeholder="First Name"
          name="firstName"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Your surname. <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="surname"
          defaultValue={
            commonLayoutData !== null ? commonLayoutData.lastName : ""
          }
          name="lastName"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Your email address. <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          ref={register({ required: true })}
          defaultValue={commonLayoutData !== null ? commonLayoutData.email : ""}
          className="py-2"
          name="email"
          placeholder="you@domain.com"
        />
        <Form.Label className="form__label my-3">
          Your university email (if you are a student).
        </Form.Label>
        <Form.Control
          type="email"
          ref={register}
          className="py-2"
          defaultValue={
            commonLayoutData !== null ? commonLayoutData.universityEmail : ""
          }
          name="universityEmail"
          placeholder="you@domain.com"
        />
        <Form.Label className="form__label my-3">
          I would like to nominate in the following area.
          <span className="astrix-color">*</span>
        </Form.Label>
        <select
          name="nominate"
          className="select-form p-2"
          defaultValue={
            commonLayoutData !== null ? commonLayoutData.nominate : ""
          }
          ref={register({ required: true })}
          onChange={e => setNominate(e.target.value)}
        >
          <option value="">
            Please select which area you would like to nominate for
          </option>
          <option value="Undergraduate Awards">Undergraduate Awards</option>
          <option value="Aspire Awards">Aspire Awards</option>
          <option value="Widening Participation Awards">
            Widening Participation Awards
          </option>
          <option value="Employer Awards">Employer Awards</option>
          <option value="Mentor Award">Mentor Award</option>
        </select>
        <Button className="submit-form mt-5 p-2" type="submit">
          Next
        </Button>
      </Form>
    </StyledWrapper>
  );
}
