import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const EligibilitySchool = ({
  setStep,
  setInternalStep,
  internalStep,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Who is making the nomination?</h2>
        <Form.Label className="form__label my-3">
          Are you nominating your own school for this award?
          <span className="astrix-color">*</span>
        </Form.Label>
        <select
          name="nominating_your_own_school_for_this_award"
          className="select-form p-2"
          ref={register({ required: true })}
        >
          <option value="">Select your option</option>
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select>
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default EligibilitySchool;
