import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import UndergraduateMainOne from "./UndergraduateComponent1";
import AcademicAchievement from "./AcademicAchievment";
import AcademicAchievementOne from "./AcademicAchievment1";
import Sector from "./Sector";
import StudentBlock from "../final Block/StudentBlock";
import Privacy from "../final Block/Gdpr";
import SectorOne from "./sectorOne";
import SectorTwo from "./SectorTwo";
import SocialMobility from "./SocialMobility";
import SocialMobilityOne from "./SocialMobilityOne";
const StyledWrapper = styled.div`
  .submit-form {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .back-btn {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .form__label {
    font-family: Nunito Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    display: block;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }

  .astrix-color {
    color: #e43d5a;
  }
`;

const UndergraduateMain = ({
  step,
  setStep,
  commonLayoutData,
  setCommonLayoutData,
  submitForm,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [internalStep, setInternalStep] = useState(1);
  const [undergraduateData, setUndergraduateData] = useState(null);
  const onSubmit = data => {
    submitForm({ ...data });
  };
  return (
    <StyledWrapper>
      {internalStep == 1 && (
        <UndergraduateMainOne
          formData={formData}
          setFormData={setFormData}
          setStep={setStep}
          internalStep={internalStep}
          setInternalStep={setInternalStep}
        />
      )}
      {internalStep === 2 &&
        formData.reason_for_undergraduate_award === "Academic achievement" && (
          <AcademicAchievement
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 3 &&
        formData.reason_for_undergraduate_award === "Academic achievement" && (
          <AcademicAchievementOne
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 4 &&
        formData.reason_for_undergraduate_award === "Academic achievement" && (
          <StudentBlock
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 5 &&
        formData.reason_for_undergraduate_award === "Academic achievement" && (
          <Privacy
            submit={onSubmit}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}

      {internalStep === 2 &&
        formData.reason_for_undergraduate_award ===
          "Specific career sector achievement" && (
          <Sector
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 3 &&
        formData.reason_for_undergraduate_award ===
          "Specific career sector achievement" && (
          <SectorOne
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 4 &&
        formData.reason_for_undergraduate_award ===
          "Specific career sector achievement" && (
          <SectorTwo
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 5 &&
        formData.reason_for_undergraduate_award ===
          "Specific career sector achievement" && (
          <StudentBlock
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 6 &&
        formData.reason_for_undergraduate_award ===
          "Specific career sector achievement" && (
          <Privacy
            submit={onSubmit}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 2 &&
        formData.reason_for_undergraduate_award ===
          "Improving social mobility on a national or local level" && (
          <SocialMobility
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 3 &&
        formData.reason_for_undergraduate_award ===
          "Improving social mobility on a national or local level" && (
          <SocialMobilityOne
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 4 &&
        formData.reason_for_undergraduate_award ===
          "Improving social mobility on a national or local level" && (
          <StudentBlock
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 5 &&
        formData.reason_for_undergraduate_award ===
          "Improving social mobility on a national or local level" && (
          <Privacy
            submit={onSubmit}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
    </StyledWrapper>
  );
};

export default UndergraduateMain;
