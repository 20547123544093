import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, Button, Dropdown } from "react-bootstrap";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const ContactDetailsSchool = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Their contact details </h1>

        <Form.Label className="form__label my-3">
          What is the name of your school/college you are nominating?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the name of your school/college you are nominating"
          name="What is the name of your school/college you are nominating?"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is the postcode?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the postcode"
          name="What is the postcode?"
          ref={register({ required: true })}
        />

        <Form.Label className="form__label my-3">
          Please provide the full name of a contact at the school/college.
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the full name of the contact at school/college"
          name="Please provide the full name of a contact at the school/college."
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Please provide an email address. We’ll contact this person for
          supplementary information in support of their nomination.
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter the email address"
          name="Please provide an email address We’ll contact this person for supplementary information in support of their nomination."
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default ContactDetailsSchool;
