import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";

const StyledWrapper = styled.div``;

const StudentBlock = ({
  setStep,
  setInternalStep,

  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setFormData({ ...formData, ...data });

    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>The Student Vote</h1>
        <Form.Label className="form__label my-3">
          Are you an undergraduate? Would you like to help decide the outcome of
          several Award categories in the Student Vote? If so, share your
          university email address below and we'll send you a message in May
          once shortlisting has been completed so that you can vote for your
          winners! Please note that only students who are undergraduates in the
          2021-22 academic year are eligible to vote in the Student Vote.
        </Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          name="Are you an undergraduate? Would you like to help decide the outcome of several Award categories in the Student Vote? If so, share your university email address below and we'll send you a message in May once shortlisting has been completed so that you can vote for your winners! Please note that only students who are undergraduates in the 2021-22 academic year are eligible to vote in the Student Vote."
          ref={register({ required: false })}
        />
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default StudentBlock;
