import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, Button, Dropdown } from "react-bootstrap";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const ContactDetails = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Their contact details </h1>

        <Form.Label className="form__label my-3">
          What is the first name of the student you are nominating?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter the first name of the nominating student."
          name="nominating_student_first_name"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is their surname?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter the surname of the nominating student."
          name="nominating_student_surname"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is their email address?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Please enter the email address of the nominating student."
          name="nominating_student_email_address"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What school, sixth-form college or FE college do they attend?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter which college they attend."
          name="What school, sixth-form college or FE college do they attend"
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default ContactDetails;
