import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import { useEffect } from "react";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const AboutYourself = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit } = useForm();
  const [university, setUniversity] = useState([]);
  const [wordLength, setWordLength] = useState(null);
  const [wordLengthThree, setWordLengthThree] = useState(null);
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  useEffect(() => {
    fetchUniversities();
  }, []);

  async function fetchUniversities() {
    const response = await fetch(
      `${enkiUrl}/universities/get-active-universities`
    );
    const responseJson = await response.json();
    let universities = responseJson.universities;
    universities.sort((a, b) => a.name > b.name);
    setUniversity(universities);
  }
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  const handelLength = val => {
    if (val.split(" ").length >= 200) {
      setWordLength(val.length);
      return;
    } else if (val.split(" ").length < 200) {
      setWordLength(null);
    }
  };
  const handelLengthThree = val => {
    if (val.split(" ").length >= 75) {
      setWordLengthThree(val.length);
      return;
    } else if (val.split(" ").length < 75) {
      setWordLengthThree(null);
    }
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Tell us about the initiative</h1>
        <Form.Label className="form__label my-3">
          Which university runs the initiative in question?
          <span className="astrix-color">*</span>
        </Form.Label>
        <select
          name="Which university runs the initiative in question"
          className="select-form p-2"
          ref={register({ required: true })}
        >
          <option value="">Select your university</option>
          {university.length > 0 &&
            university.map(
              item =>
                item.name !== "test" && (
                  <option value={item.name}>{item.name}</option>
                )
            )}
        </select>
        <Form.Label className="form__label my-3">
          What is the name of the initiative?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the name of the initiative"
          name="What is the name of the initiative"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Briefly describe the initiative (20 words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Describe the initiative"
          name="Briefly describe the initiative"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Describe the initiative, including what activities have taken place,
          with a focus on activity in the last two academic years (200 words
          max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          placeholder=""
          onChange={e => handelLength(e.target.value)}
          maxLength={wordLength}
          name="Describe the initiative, including what activities have taken place, with a focus on activity in the last two academic years"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Describe the impact of this initiative, with a focus on outcomes
          achieved in 2021-22, numbers supported and any benchmarking. (200
          words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          onChange={e => handelLength(e.target.value)}
          maxLength={wordLength}
          placeholder=""
          name="Describe the impact of this initiative, with a focus on outcomes achieved in 2021-22, numbers supported and any benchmarking."
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Is there anything you would like to add? (75 words max)
        </Form.Label>
        <Form.Control
          maxLength={wordLengthThree}
          onChange={e => handelLengthThree(e.target.value)}
          as="textarea"
          placeholder=""
          name="Is there anything you would like to add"
          ref={register({ required: false })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default AboutYourself;
