import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";

const StyledWrapper = styled.div``;

const FailSubmit = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
  submit,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    submit(formData);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <p>
          <strong>
            We're sorry. You aren't eligible to nominate for this Award. For
            more information on eligibility, please click 'About' at the top of
            this page and read the eligibility criteria in the Awards section.
          </strong>
        </p>

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default FailSubmit;
