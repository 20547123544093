import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, Button, Dropdown } from "react-bootstrap";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const NominatingThem = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [wordLength, setWordLength] = useState(null);
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };

  const handelLength = val => {
    if (val.split(" ").length >= 200) {
      setWordLength(val.length);
      return;
    } else if (val.split(" ").length < 200) {
      setWordLength(null);
    }
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Why are you nominating them?</h1>

        <Form.Label className="form__label my-3">
          Why are you nominating this teacher for this award? How have they gone
          above and beyond, making a significant contribution to broadening
          career horizons, boosting aspirations or facilitating the future
          success of students? Please give particular focus on activities that
          have taken place in the 2021-22 academic year. (200 words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          onChange={e => handelLength(e.target.value)}
          maxLength={wordLength}
          as="textarea"
          placeholder=""
          name="Why are you nominating this teacher for this award? How have they gone above and beyond, making a significant contribution to broadening career horizons, boosting aspirations or facilitating the future success of students? Please give particular focus on activities that have taken place in the 2021-22 academic year."
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default NominatingThem;
