import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const ContactDetailsIndividualOne = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Contact details</h1>

        <Form.Label className="form__label my-3">
          Their first name
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter their first name"
          name="Their first name"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Their last name
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter their last name"
          name="Their last name"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Please provide their email address. We need this to contact them for
          supplementary information in support of your nomination.
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter their email address"
          name="Please provide their email address We need this to contact them for supplementary information in support of your nomination."
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Their employer
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter their employer"
          name="Their employer"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Their work location
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter their location"
          name="Their work location*"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is your relationship to the nominee?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your relationship to the nominee"
          name="What is your relationship to the nominee?*"
          ref={register({ required: true })}
        />
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default ContactDetailsIndividualOne;
