import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  .heading,
  .subtext {
    letter-spacing: ${props => props.theme["zero"]};
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .heading {
    color: ${props => props.theme["primaryColor"]} !important;
    font-size: ${props => props.theme["subHeadingSize"]} !important;
    font-weight: ${props => props.theme["headingBold"]} !important;
    line-height: ${props => props.theme["h2LineHeight"]} !important;

    @media (max-width: 600px) {
      font-size: 15px !important;
      line-height: unset !important;
    }
  }

  .sub-heading-light {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .subtext {
    font-size: 20px !important;
    font-weight: ${props => props.theme["textBold"]} !important;
    line-height: ${props => props.theme["subTextLineHeight"]} !important;
    padding-left: 2%;
    padding-right: 2%;
    @media (max-width: 600px) {
      font-size: 14px !important;
    }
    color: black;
  }

  .MuiAccordionDetails-root {
    display: flex;
    padding: 8px 16px 40px;
  }

  .MuiIconButton-label {
    color: #000000;
    font-size: 42px;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding-left: 0px !important;
  }

  .MuiAccordion-root.Mui-expanded:first-child {
    margin-bottom: 0px;
  }

  .MuiAccordion-root.Mui-expanded:last-child {
    border-bottom: none !important;
  }

  .MuiAccordion-root:last-child {
    border-bottom: none !important;
  }

  .accordion-tab {
    border-bottom: 3px solid #f2b842;
    background-color: white;
    box-shadow: none;
  }

  h1 {
    @media (max-width: 600px) {
      line-height: 30px;
    }
  }

  .emp-heading {
    text-transform: uppercase;
    line-height: normal !important;
    flex-basis: 100%;
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .react-desc a {
    text-decoration: underline;
    color: black;
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .sub-heading-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank">
      {props.children}
    </a>
  );
}

export default function ControlledAccordions({ data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <StyledWrapper className="white-background pb-3 padding-left-8 padding-right-8">
      <h2 className="mb-1 header-text">{data.Heading}</h2>
      <h5 className="mb-1 sub-heading-text">{data.SubHeading}</h5>
      <div className={classes.root}>
        {React.Children.toArray(
          data.AccordionData.length > 0 &&
            data.AccordionData.map((item, index) => (
              <Accordion
                className="accordion-tab"
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={expanded === index ? "-" : "+"}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography
                    className={classes.heading + " heading emp-heading"}
                  >
                    {item.heading}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    className={classes.secondaryHeading + " subtext pl-0"}
                  >
                    <div className="row">
                      <div className="react-desc col-12">
                        <ReactMarkdown source={item.description} />
                      </div>
                      {/* {(item.image !== null || item.image.publicURL !== undefined) ? <div className="col-lg-6 d-flex justify-content-center"><img src={item.image.publicURL} /></div> : <></>} */}
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))
        )}
      </div>
    </StyledWrapper>
  );
}
