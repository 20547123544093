import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import AspireMainOne from "./AspireMainOne";
import Eligibility from "./Eligibilty";
import FailBlock from "../Fail Block/Fail";
import StudentBlock from "../final Block/StudentBlock";
import Privacy from "../final Block/Gdpr";
import ContactDetails from "./ContactDetails";
import Achievements from "./Achivements";
import AchievementOne from "./AchivementOne";
import EligibilityTeacher from "./EligiblityTeacher";
import AboutYourself from "./AboutYourself";
import FailTeachers from "../Fail Block/FailTeachers";
import EligibilityTeacherTwo from "./EligiblityTeacherTwo";
import ContactDetailsTeacher from "./contactDetailsTeacher";
import AboutYourselfSchool from "./AboutYourSelfSchool";
import NominatingThem from "./nominatingThem";
import ContactDetailsSchool from "./ContactDetailsSchool";
import NominatingSchool from "./NominatingSchool";
import EligibilitySchool from "./EligibilitySchool";
const StyledWrapper = styled.div`
  .submit-form {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .back-btn {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .form__label {
    font-family: Nunito Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    display: block;
    letter-spacing: 0px;
    text-align: left;
  }

  .astrix-color {
    color: #e43d5a;
  }
`;

const AspireMain = ({
  step,
  setStep,
  commonLayoutData,
  setCommonLayoutData,
  submitForm,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    submitForm({ ...data });
  };
  const [internalStep, setInternalStep] = useState(1);
  return (
    <StyledWrapper>
      {internalStep == 1 && (
        <AspireMainOne
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          internalStep={internalStep}
          setInternalStep={setInternalStep}
        />
      )}
      {internalStep == 2 &&
        (formData.aspire_award === "Aspire Rising Star" ||
          formData.aspire_award === "Aspire Community Champion") && (
          <Eligibility
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 3 &&
        (formData.aspire_award === "Aspire Rising Star" ||
          formData.aspire_award === "Aspire Community Champion") &&
        formData.nominating_yourself_for_this_award === "yes" && (
          <FailBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep === 4 &&
        (formData.aspire_award === "Aspire Rising Star" ||
          formData.aspire_award === "Aspire Community Champion") &&
        formData.nominating_yourself_for_this_award === "yes" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep === 5 &&
        (formData.aspire_award === "Aspire Rising Star" ||
          formData.aspire_award === "Aspire Community Champion") &&
        formData.nominating_yourself_for_this_award === "yes" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep === 3 &&
        (formData.aspire_award === "Aspire Rising Star" ||
          formData.aspire_award === "Aspire Community Champion") &&
        formData.nominating_yourself_for_this_award === "no" && (
          <ContactDetails
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep === 4 &&
        formData.aspire_award === "Aspire Rising Star" &&
        formData.nominating_yourself_for_this_award === "no" && (
          <Achievements
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep === 4 &&
        formData.aspire_award === "Aspire Community Champion" &&
        formData.nominating_yourself_for_this_award === "no" && (
          <AchievementOne
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}

      {internalStep === 5 &&
        (formData.aspire_award === "Aspire Rising Star" ||
          formData.aspire_award === "Aspire Community Champion") &&
        formData.nominating_yourself_for_this_award === "no" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 2 &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <Eligibility
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_yourself_for_this_award === "no" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <EligibilityTeacherTwo
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_yourself_for_this_award === "no" &&
        formData.Has_the_teacher_you_are_nominating_taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "yes" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <ContactDetailsTeacher
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_yourself_for_this_award === "no" &&
        formData.Has_the_teacher_you_are_nominating_taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "no" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <FailTeachers
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_yourself_for_this_award === "no" &&
        formData.Has_the_teacher_you_are_nominating_taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "yes" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <NominatingThem
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_yourself_for_this_award === "no" &&
        formData.Has_the_teacher_you_are_nominating_taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "yes" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 7 &&
        formData.nominating_yourself_for_this_award === "no" &&
        formData.Has_the_teacher_you_are_nominating_taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "yes" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}

      {internalStep == 3 &&
        formData.nominating_yourself_for_this_award === "yes" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <EligibilityTeacher
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_yourself_for_this_award === "yes" &&
        formData.taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "yes" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <AboutYourself
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_yourself_for_this_award === "yes" &&
        formData.taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "no" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <FailTeachers
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep === 5 &&
        formData.nominating_yourself_for_this_award === "yes" &&
        formData.taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022 ===
          "yes" &&
        formData.aspire_award === "Aspire Teacher of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}

      {internalStep == 2 &&
        formData.aspire_award === "Aspire School of the Year" && (
          <EligibilitySchool
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}

      {internalStep == 3 &&
        formData.nominating_your_own_school_for_this_award === "yes" &&
        formData.aspire_award === "Aspire School of the Year" && (
          <AboutYourselfSchool
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_school_for_this_award === "yes" &&
        formData.aspire_award === "Aspire School of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_school_for_this_award === "no" &&
        formData.aspire_award === "Aspire School of the Year" && (
          <ContactDetailsSchool
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_school_for_this_award === "no" &&
        formData.aspire_award === "Aspire School of the Year" && (
          <NominatingSchool
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_school_for_this_award === "no" &&
        formData.aspire_award === "Aspire School of the Year" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_your_own_school_for_this_award === "no" &&
        formData.aspire_award === "Aspire School of the Year" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
    </StyledWrapper>
  );
};

export default AspireMain;
