import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import WideningMainOne from "./WideningMainOne";
import Nomination from "./Nomination";
import AboutYourself from "./AboutYourself";
import Referee from "./Referee";
import Privacy from "../final Block/Gdpr";
import ContactDetailsUniversity from "./ContactDetailsUniversity";
import Initiative from "./Tellus";
import StudentBlock from "../final Block/StudentBlock";
import Organization from "./organisation";
import ContactDetailsOrganisation from "./ContactDetailsOrganisation";
import AboutStudentSuccessInitiative from "./AboutStudentSuccessInitiative";
import AboutUniversityProgression from "./AboutUniversityProgression";
const StyledWrapper = styled.div`
  .submit-form {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .back-btn {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .form__label {
    font-family: Nunito Sans;
    font-size: 21px;
    display: block;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }

  .astrix-color {
    color: #e43d5a;
  }
`;

const WideningMain = ({
  step,
  setStep,
  commonLayoutData,
  setCommonLayoutData,
  submitForm,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    submitForm({ ...data });
  };
  const [internalStep, setInternalStep] = useState(1);
  return (
    <StyledWrapper>
      {internalStep == 1 && (
        <WideningMainOne
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          internalStep={internalStep}
          setInternalStep={setInternalStep}
        />
      )}
      {internalStep == 2 &&
        formData.widening_award === "University Access Initiative" && (
          <Nomination
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Access Initiative" && (
          <AboutYourself
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Access Initiative" && (
          <Referee
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Access Initiative" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Access Initiative" && (
          <ContactDetailsUniversity
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Access Initiative" && (
          <Initiative
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Access Initiative" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Access Initiative" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}

      {internalStep == 2 &&
        formData.widening_award === "University Student Success Initiative" && (
          <Nomination
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Student Success Initiative" && (
          <AboutYourself
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Student Success Initiative" && (
          <Referee
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Student Success Initiative" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Student Success Initiative" && (
          <ContactDetailsUniversity
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Student Success Initiative" && (
          <AboutStudentSuccessInitiative
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Student Success Initiative" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Student Success Initiative" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}

      {internalStep == 2 &&
        formData.widening_award === "University Progression Initiative" && (
          <Nomination
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Progression Initiative" && (
          <AboutYourself
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Progression Initiative" && (
          <Referee
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award === "University Progression Initiative" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Progression Initiative" && (
          <ContactDetailsUniversity
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Progression Initiative" && (
          <AboutUniversityProgression
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Progression Initiative" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award === "University Progression Initiative" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 2 &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <Nomination
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <Organization
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <Referee
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award ===
          "Yes, I am employed by the organisation in question" &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
      {internalStep == 3 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <ContactDetailsOrganisation
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 4 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <Organization
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 5 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <StudentBlock
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
          />
        )}
      {internalStep == 6 &&
        formData.nominating_your_own_organisation_for_this_award === "no" &&
        formData.widening_award ===
          "Highest Impact Widening Participation Organisation" && (
          <Privacy
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            internalStep={internalStep}
            setInternalStep={setInternalStep}
            submit={onSubmit}
          />
        )}
    </StyledWrapper>
  );
};

export default WideningMain;
