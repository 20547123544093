import React, { useState } from "react";
import NominationFormCommonLayout from "./NominationFormComponents/CommonLayout";
import Swal from "sweetalert2";
import styled from "styled-components";
import ReactGA from "react-ga";
import UndergraduateMain from "./NominationFormComponents/Undergraduate Awards/UndergraduteMain";
import AspireMain from "./NominationFormComponents/Aspire Awards/AspireMain";
import WideningMain from "./NominationFormComponents/Widening Participation Awards/WideningMain";
import EmployerMain from "./NominationFormComponents/Employer Award/EmployerMain";
import MentorMain from "./NominationFormComponents/Mentor Awards/MentorMain";
const StyledWrapper = styled.div`
  background-color: white;
  width: 80%;
  padding: 5% 8%;
  overflow: scroll;
`;

const NominationForm = ({ handleClose }) => {
  const [commonLayoutData, setCommonLayoutData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [step, setStep] = useState(1);
  const [submissionData, setSubmissionData] = useState(null);
  const [error, setError] = React.useState("");

  const submitForm = data => {
    fetch("https://formspree.io/f/meqnawve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status == 200) {
          handleClose();
          Swal.fire({
            closeButtonHtml: '<span style="color:black">&times</span>',
            width: "1000px",
            className: "swal2",
            background: "#F2B842",
            html:
              '<h2 style="font-size:24px;font-weight:800;font-family:Nunito Sans;text-align:left;padding-bottom:10px;">Your form has been submitted</h2><h2 style="font-size:17px;font-weight:400;font-family:Nunito Sans;line-height:24px;text-align:left">Thank you for making a nomination for the Student Social Mobility Awards. We will be contacting nominees directly for any supplementary information. If you would like to make another nomination, please fill out the nomination form again! Please direct any questions regarding nominations to nominations@ssma.org.uk.</h2>',
            showCloseButton: true,
            showConfirmButton: false,
          });
        } else {
          return response.json().then(data => setError(data.message));
        }
      })
      .catch(err => console.log(err));
    ReactGA.event({
      category: "Nominations Form",
      action: "Submit Button Click",
    });
  };

  return (
    <StyledWrapper>
      <div>
        <h1>Nomination Form for the 2022 Student Social Mobility Awards</h1>
        {step == 1 && (
          <NominationFormCommonLayout
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            setCommonLayoutData={setCommonLayoutData}
            commonLayoutData={commonLayoutData}
          />
        )}
        {step == 2 && commonLayoutData.nominate == "Undergraduate Awards" && (
          <UndergraduateMain
            formData={formData}
            setFormData={setFormData}
            step={step}
            setStep={setStep}
            commonLayoutData={commonLayoutData}
            setCommonLayoutData={setCommonLayoutData}
            submitForm={submitForm}
          />
        )}
        {step == 2 && commonLayoutData.nominate === "Aspire Awards" && (
          <AspireMain
            formData={formData}
            setFormData={setFormData}
            step={step}
            setStep={setStep}
            commonLayoutData={commonLayoutData}
            setCommonLayoutData={setCommonLayoutData}
            submitForm={submitForm}
          />
        )}
        {step === 2 &&
          commonLayoutData.nominate === "Widening Participation Awards" && (
            <WideningMain
              formData={formData}
              setFormData={setFormData}
              step={step}
              setStep={setStep}
              commonLayoutData={commonLayoutData}
              setCommonLayoutData={setCommonLayoutData}
              submitForm={submitForm}
            />
          )}

        {step === 2 && commonLayoutData.nominate === "Employer Awards" && (
          <EmployerMain
            formData={formData}
            setFormData={setFormData}
            step={step}
            setStep={setStep}
            commonLayoutData={commonLayoutData}
            setCommonLayoutData={setCommonLayoutData}
            submitForm={submitForm}
          />
        )}
        {step === 2 && commonLayoutData.nominate === "Mentor Award" && (
          <MentorMain
            formData={formData}
            setFormData={setFormData}
            step={step}
            setStep={setStep}
            commonLayoutData={commonLayoutData}
            setCommonLayoutData={setCommonLayoutData}
            submitForm={submitForm}
          />
        )}
      </div>
    </StyledWrapper>
  );
};

export default NominationForm;
