import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import { useEffect } from "react";
const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
  .form__label {
    display: block;
  }
`;

const ContactDetailsUniversity = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [university, setUniversity] = useState([]);
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  useEffect(() => {
    fetchUniversities();
  }, []);

  async function fetchUniversities() {
    const response = await fetch(
      `${enkiUrl}/universities/get-active-universities`
    );
    const responseJson = await response.json();
    let universities = responseJson.universities;
    universities.sort((a, b) => a.name > b.name);
    setUniversity(universities);
  }
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };

  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Contact details</h1>
        <Form.Label className="form__label my-3">
          Which university runs the access initiative you are nominating?
          <span className="astrix-color">*</span>
        </Form.Label>
        <select
          name="Which university runs the access initiative you are nominating"
          className="select-form p-2"
          ref={register({ required: true })}
        >
          <option value="">Select your university</option>
          {university.length > 0 &&
            university.map(
              item =>
                item.name !== "test" && (
                  <option value={item.name}>{item.name}</option>
                )
            )}
        </select>
        <Form.Label className="form__label my-3">
          What is the name of the initiative in question?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the name of the initiative in question"
          name="What is the name of the initiative in question"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Please provide the full name of an employee at the university involved
          in this initiative
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the full name of an employee at the university involved in this initiative"
          name="Please provide the full name of an employee at the university involved in this initiative"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Please provide their email address
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter the email address"
          name="Please provide their email address"
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default ContactDetailsUniversity;
