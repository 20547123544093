import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const ContactDetailsMentor = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Contact details</h1>

        <Form.Label className="form__label my-3">
          What is the first name of the mentor you are nominating?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter the first name of the mentor you are nominating"
          name="What is the first name of the mentor you are nominating?"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is their surname?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter their surname"
          name="What is their surname?"
          ref={register({ required: true })}
        />

        <Form.Label className="form__label my-3">
          What is their email address?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter their email address"
          name="What is their email address?"
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default ContactDetailsMentor;
