import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
const StyledWrapper = styled.div`
  background-color: white;
  width: 80%;
  padding: 5% 8%;
  overflow: scroll;
  .form-input {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }

  .btn-class {
    height: 50px;
    width: 180px;
    background: #f2b842;
    font-size: 20px;
    border: none;
    font-weight: 800;
    padding: 10px 0;
    margin-top: 30px;
    border-radius: 5px;
  }

  .label-class {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
  }

  .input-label {
    border: 1px solid #f2b842;
    border-radius: 5px;
    padding: 10px;
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .cancel-btn {
    height: 50px;
    width: 180px;
    color: #464fd0;
    font-size: 20px;
    border: none;
    font-weight: 800;
    border-radius: 5px;
  }
`;

export default function Form({ handleClose, notify }) {
  const { register, handleSubmit, watch, errors } = useForm();
  const [error, setError] = React.useState("");

  const submit = data => {
    fetch("https://formspree.io/f/mgepgdgg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status == 200) {
          handleClose();
          Swal.fire({
            closeButtonHtml: '<span style="color:black">&times</span>',
            width: "1000px",
            className: "swal2",
            background: "#F2B842",
            html:
              '<h2 style="font-size:24px;font-weight:800;font-family:Nunito Sans;text-align:left;padding-bottom:10px;">Your form has been submitted</h2><h2 style="font-size:17px;font-weight:400;font-family:Nunito Sans;line-height:24px;text-align:left">Thank you for contacting upReach about the Student Social Mobility Awards with your request. upReach will aim to respond within two working days.</h2>',
            showCloseButton: true,
            showConfirmButton: false,
          });
        } else {
          return response.json().then(data => setError(data.message));
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <StyledWrapper className="padding-left-8 padding-right-8 col-lg-8 col-12">
      <h1 className="header-text">Student Vote Form</h1>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-input">
          <label htmlFor="Name" className="label-class">
            Name<span style={{ color: "#E43D5A" }}>*</span>
          </label>
          <input
            id="Name"
            className="input-label"
            type="text"
            placeholder="Name"
            aria-invalid={errors.firstName ? "true" : "false"}
            name="Name"
            ref={register({ required: true })}
          />
        </div>
        <div className="form-input">
          <label htmlFor="Email" className="label-class">
            University Email<span style={{ color: "#E43D5A" }}>*</span>
          </label>
          <input
            id="University-email"
            className="input-label"
            type="email"
            placeholder="you@domain.com"
            aria-invalid={errors.firstName ? "true" : "false"}
            name="UniversityEmail"
            ref={register({ required: true })}
          />
        </div>
        <div className="form-input pb-4">
          <label htmlFor="Email" className="label-class">
            Personal Email<span style={{ color: "#E43D5A" }}>*</span>
          </label>
          <input
            id="Personal-email"
            className="input-label"
            type="email"
            placeholder="you@domain.com"
            aria-invalid={errors.firstName ? "true" : "false"}
            name="PersonalEmail"
            ref={register({ required: true })}
          />
        </div>
        <div className="form-check mt-1">
          <input
            className="form-check-input"
            type="checkbox"
            name="privacy_consent"
            ref={register({ required: true })}
          />
          <label className="form-check-label">
            Please contact me in July when the Student Vote opens
          </label>
        </div>
        {error !== "" && <div>Form not submitted. {error}</div>}
        <button onClick={handleClose} className="mr-5 mr-md-5 cancel-btn">
          CANCEL
        </button>
        <button type="submit" className="btn-class">
          SUBMIT
        </button>
      </form>
    </StyledWrapper>
  );
}
