import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const EligibilityTeacher = ({
  setStep,
  setInternalStep,
  internalStep,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>Eligibility</h2>
        <Form.Label className="form__label my-3">
          Have you taught pupils aged 16 and over at a school, sixth form
          college or FE college in the UK in the 2021-22 academic year?
          <span className="astrix-color">*</span>
        </Form.Label>
        <select
          name="taught_pupils_aged_16_and_over_at_a_school_sixth_form_or_fE_college_in_uk_in_2021_2022"
          className="select-form p-2"
          ref={register({ required: true })}
        >
          <option value="">Select your option</option>
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select>
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default EligibilityTeacher;
