import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";

const StyledWrapper = styled.div`
  textarea {
    height: 9rem;
  }
`;

const Initiative = ({
  setStep,
  setInternalStep,

  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [wordLength, setWordLength] = useState(null);
  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  const handelLength = val => {
    if (val.split(" ").length >= 200) {
      setWordLength(val.length);
      return;
    } else if (val.split(" ").length < 200) {
      setWordLength(null);
    }
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Tell us about the initiative</h1>
        <Form.Label className="form__label my-3">
          Please provide a statement on the activities you have undertaken as
          part of this initiative, describing when these activities took place
          and the impact on you and your fellow students. (200 words max)
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          onChange={e => handelLength(e.target.value)}
          maxLength={wordLength}
          as="textarea"
          placeholder=""
          name="Please provide a statement on the activities you have undertaken as part of this initiative, describing when these activities took place and the impact on you and your fellow students."
          ref={register({ required: true })}
        />
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default Initiative;
