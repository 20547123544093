import React, { useState } from "react";
import styled from "styled-components";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useEffect } from "react";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const AcademicAchievement = ({
  setStep,
  setInternalStep,
  internalStep,
  formData,
  setFormData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [university, setUniversity] = useState([]);
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  useEffect(() => {
    fetchUniversities();
  }, []);
  async function fetchUniversities() {
    const response = await fetch(
      `${enkiUrl}/universities/get-active-universities`
    );
    const responseJson = await response.json();
    let universities = responseJson.universities;
    universities.sort((a, b) => a.name > b.name);
    setUniversity(universities);
  }

  const onSubmit = data => {
    setFormData({ ...formData, ...data });

    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Their contact details </h1>
        <p className="Text-muted">
          Contact details for the student you are nominating
        </p>
        <Form.Label className="form__label my-3">
          What is the first name of the student you are nominating?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter the first name of the nominating student."
          name="nominating_student_first_name"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is their surname?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter the surname of the nominating student."
          name="nominating_student_surname"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          What is their email address?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Please enter the email address of the nominating student."
          name="nominating_student_email_address"
          ref={register({ required: true })}
        />

        <Form.Label className="form__label my-3">
          Please select their University from the drop-down list.
          <span className="astrix-color">*</span>
        </Form.Label>
        <select
          name="Uk university"
          className="select-form p-2"
          ref={register({ required: true })}
        >
          <option value="">Select their university</option>
          {university.length > 0 &&
            university.map(
              item =>
                item.name !== "test" && (
                  <option value={item.name}>{item.name}</option>
                )
            )}
        </select>
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default AcademicAchievement;
