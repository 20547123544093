import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, Button, Dropdown } from "react-bootstrap";

const StyledWrapper = styled.div`
  .form__label {
    display: block;
  }
`;

const ContactDetailsTeacher = ({
  setStep,
  setInternalStep,
  formData,
  setFormData,
  internalStep,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    setFormData({ ...formData, ...data });
    setInternalStep(internalStep + 1);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Their contact details </h1>

        <Form.Label className="form__label my-3">
          Their first name?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter the first name."
          name="Their first name"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Their surname?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter the surname."
          name="Their surname"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Their email Address?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Please enter the email address."
          name="Their email Address"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Their school/sixth-form college?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter which school/sixth-form college they attend."
          name="Their school/sixth-form college"
          ref={register({ required: true })}
        />
        <Form.Label className="form__label my-3">
          Postcode?
          <span className="astrix-color">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please enter the Postcode."
          name="postcode"
          ref={register({ required: true })}
        />

        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};

export default ContactDetailsTeacher;
