import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";

const StyledWrapper = styled.div`
  .submit-form {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .back-btn {
    background-color: rgb(242, 184, 66);
    color: black;
    font-size: 21px;
    font-weight: bold;
    width: 60%;
    border: 0px;
  }

  .form__label {
    font-family: Nunito Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }

  .astrix-color {
    color: #e43d5a;
  }

  .form__label {
    font-family: Nunito Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
  }

  .anchor-link-tag {
    color: black;
    text-decoration: underline;
  }

  .warning-msg {
    color: red;
    font-size: 14px;
    padding: 10px 0;
  }
`;

const Privacy = ({
  setStep,
  setInternalStep,
  setUndergraduateData,
  undergraduateData,
  formData,
  setFormData,
  internalStep,
  submit,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = data => {
    if (privacyChecked === false || confirmChecked === false) {
      setShowMessage(true);
      return;
    }
    setFormData({ ...data, ...formData });

    submit(formData);
  };
  return (
    <StyledWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>GDPR</h2>
        <div className="form-check mt-3">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={e => setPrivacyChecked(!privacyChecked)}
            name="privacy_consent"
            ref={register}
          />
          <label className="form-check-label">
            By ticking this box, I agree to upReach processing my data in line
            with their 2022 Student Social Mobility Awards{" "}
            <a
              target="__blank"
              href="https://cms.studentsocialmobilityawards.org.uk/uploads/13_c25714618b.pdf"
              className="anchor-link-tag"
            >
              Privacy Notice
            </a>
            .
          </label>
        </div>
        <div className="form-check mt-3">
          <input
            className="form-check-input"
            type="checkbox"
            onClick={() => setConfirmChecked(!confirmChecked)}
            name="confirm_nomination"
            ref={register}
          />
          <label className="form-check-label">
            By ticking this box, I confirm I have sought the consent of the
            individual I am nominating for this Award/nominating as a referee
            for this Award to do so.
          </label>
        </div>
        {showMessage === true && (
          <div className="warning-msg">
            In order to complete your nomination you must agree to the above
            terms
          </div>
        )}
        <div className="row">
          <div className="col-6">
            <Button
              className="back-btn mt-3 p-2"
              onClick={() => setInternalStep(internalStep - 1)}
            >
              Back
            </Button>
          </div>
          <div className="col-6">
            <Button type="submit" className="submit-form mt-3 p-2">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  );
};
export default Privacy;
